import ApiService from '../api-service';

class FileApiService extends ApiService {
  getHistory = async (params = {}) => await this.get('/history', params).then((res) => res.data);

  getUploads = async (params = {}) => await this.get('/upload', params).then((res) => res.data);

  upload = async (data = {}) => await this.post('/upload', data, false).then((res) => res.data)

  deleteUpload = async (id) => await this.delete(`/upload/${id}`).then((res) => res.data);

  confirmUpload = async () => await this.post('/upload/confirm').then((res) => res.data);

  updateUploadEerCodes = async (id, data) =>
    await this.post(`/upload/${id}/eer`, data, false).then((res) => res.data);

  removeUploadEerCode = async (id, eerCode) =>
    await this.delete(`/upload/${id}/eer/${eerCode}`).then((res) => res.data);

  getParsedData = async (params = {}) => await this.get('/data', params).then((res) => res.data);

  updateParsedData = async (id, data = {}, item_detail_id) =>
    await this.patch(`/data/${id}`, data, false, {item_detail_id}).then((res) => res.data);

  addParsedData = async (data) =>
    await this.post(`/data`, data).then((res) => res.data);

  deleteParsedData = async (id) =>
    await this.delete(`/data/${id}`).then((res) => res.data);

  addParsedDataItem = async (id, data) =>
    await this.post(`/data/${id}/item`, data).then((res) => res.data);

  deleteParsedDataItem = async (id, itemId) =>
    await this.delete(`/data/${id}/item/${itemId}`).then((res) => res.data);

  sendEmail = async () => await this.post('/data/send-email').then((res) => res.data);

  export = async (id = []) => await this.post('/data/export', {id}, true, false, {responseType: 'blob'}).then((res) => res.data);

  validate = async (params, eer_codes) =>
    await this.post('/data/validate', params, true, false, {params: {eer_codes}}).then((res) => res.data);

  proceed = async (id = [], eer_codes) =>
    await this.post('/validated/proceed', {id}, false, false, {params: {eer_codes}}).then((res) => res.data);

  checkTaskStatus = async (taskId) => await this.get(`/common/task_status/${taskId}`).then((res) => res.data);

  addEerCode = async (id, eerCode) => await this.post(`/data/${id}/eer/${eerCode}`).then((res) => res.data);

  removeEerCode = async (id, eerCode) => await this.delete(`/data/${id}/eer/${eerCode}`).then((res) => res.data);

  getValidatedFiles = async (params = {}) => await this.get('/validated', params).then((res) => res.data);

  updateValidatedFiles = async (id, data = {}, item_detail_id) =>
    await this.patch(`/validated/${id}`, data, false, {item_detail_id}).then((res) => res.data);

  deleteValidatedFiles = async (id) =>
    await this.delete(`/validated/${id}`).then((res) => res.data);

  validatedExport = async (params, eer_codes) =>
    await this.post('/validated/export', params, true, false, {params: {eer_codes}, responseType: 'blob'}).then((res) => res.data);

  validatedProceed = async (id = [], eer_codes) =>
    await this.post('/validated/proceed', {id}, true, false, {params: {eer_codes}}).then((res) => res.data);

  validatedRevert = async (params, eer_codes) => 
    await this.post('/validated/revert', params, true, false, {params: {eer_codes}}).then((res) => res.data);

  getFilesCount = async () => await this.get('/counter').then((res) => res.data);

  validateConstraint = async (params, eer_codes) => 
    await this.post('/data/validate-constraint', params, true, false, {params: {eer_codes}}).then((res) => res.data);

  getHazardPoints = async () => await this.get('/hp').then((res) => res.data);
}

export const FileApi = new FileApiService('files');
