import { createTheme } from '@mui/material/styles';
import ThemeEnum from './enums/theme.enum';

const darkTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", system-ui',
  },
  palette: {
    mode: ThemeEnum.DARK_MODE,
    primary: {
      main: '#1F76F1',
    },
    secondary: {
      main: '#000',
    },
    error: {
      main: '#ff4c51',
    },
    info: {
      main: '#4d4e50',
      light: '#37383a',
    },
    background: {
      default: '#000',
      paper: '#000',
      secondary: '#1F1F1F',
      contrast: '#393939'
    },
  },
});

export default darkTheme;
