import {
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  GET_COUNTS_REQUEST,
  GET_COUNTS_SUCCESS,
  GET_COUNTS_FAILURE,
  GET_HP_REQUEST,
  GET_HP_SUCCESS,
  GET_HP_FAILURE,
} from './action-types';

export const signIn = (requestData, onSuccess, onError) => ({
  type: SIGN_IN_REQUEST,
  payload: requestData,
  meta: {
    onSuccess,
    onError,
  },
});

export const signInSuccess = (responseData) => ({
  type: SIGN_IN_SUCCESS,
  payload: responseData,
});

export const signInError = (error) => ({
  type: SIGN_IN_FAILURE,
  payload: error,
});

export const logout = () => ({
  type: LOGOUT_REQUEST,
});

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
});

export const logoutError = (error) => ({
  type: LOGOUT_FAILURE,
  payload: error,
});

export const getCounts = () => ({
  type: GET_COUNTS_REQUEST,
});

export const getCountsSuccess = (responseData) => ({
  type: GET_COUNTS_SUCCESS,
  payload: responseData,
});

export const getCountsError = (error) => ({
  type: GET_COUNTS_FAILURE,
  payload: error,
});


export const getHp = () => ({
  type: GET_HP_REQUEST,
});

export const getHpSuccess = (responseData) => ({
  type: GET_HP_SUCCESS,
  payload: responseData,
});

export const getHpError = (error) => ({
  type: GET_HP_FAILURE,
  payload: error,
});
