import { createTheme } from '@mui/material/styles';
import ThemeEnum from './enums/theme.enum';

const lightTheme = createTheme({
  typography: {
    fontFamily: '"DM Sans", system-ui',
  },
  palette: {
    mode: ThemeEnum.LIGHT_MODE,
    primary: {
      main: '#1F76F1',
    },
    secondary: {
      main: '#21262D',
    },
    error: {
      main: '#ff4c51',
    },
    info: {
      main: '#d5d9e5',
      light: '#f3f3f3',
    },
    background: {
      default: '#F6F6F6',
      secondary: '#fff',
      contrast: '#e1e1e1'
    },
  },
});

export default lightTheme;
