export const AuthState = {
  authData: null,
  counts: {
    confirmed_counter: 0,
    history_counter: 0,
    validated_counter: 0,
  },
  hp: [],
  loading: false,
  error: null,
};

export const UIState = {
  successSnackbar: '',
  errorSnackbar: '',
  infoSnackbar: '',
};
