export const SIGN_IN_REQUEST = 'SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAILURE = 'SIGN_IN_FAILURE';

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_COUNTS_REQUEST = 'GET_COUNTS_REQUEST';
export const GET_COUNTS_SUCCESS = 'GET_COUNTS_SUCCESS';
export const GET_COUNTS_FAILURE = 'GET_COUNTS_FAILURE';

export const GET_HP_REQUEST = 'GET_HP_REQUEST';
export const GET_HP_SUCCESS = 'GET_HP_SUCCESS';
export const GET_HP_FAILURE = 'GET_HP_FAILURE';
