import {
  Chip,
  Collapse,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText, Typography,
  useTheme,
} from '@mui/material';
import Link from '../../../ui/link';
import React, { Fragment, useContext, useEffect, useMemo, useState } from 'react';
import { SidebarContext } from '../context/sidebar.context';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import ThemeEnum from "../../../../configs/theme/enums/theme.enum";
import {useTranslation} from "react-i18next";
import {getActiveRoute} from "../../../../helpers/url-helper";
import {useLocation} from "react-router-dom";

const SidebarItem = ({ link, open, onClick }) => {
  const [isActive, setIsActive] = useState(false);
  const sidebar = useContext(SidebarContext);
  const theme = useTheme();
  const Icon = link.Icon || (() => null);
    const location = useLocation();

    useEffect(() => {
        setIsActive(getActiveRoute() === link.link);
    }, [link, location]);

  return (
    <ListItem key={link.label} disablePadding sx={{ display: 'block' }}>
      <Link to={link.link}>
        <ListItemButton
          sx={{
            margin: '0 16px',
            minHeight: 48,
            justifyContent: sidebar.opened ? 'initial' : 'center',
            alignItems: 'center',
            px: 2.5,
            ...(isActive && {
                border: '1px solid #646B73',
                borderRadius: '4px',
                background: theme.palette.mode === ThemeEnum.LIGHT_MODE ? '#292E37' : '#1F1F1F',
            })
          }}
        >
          <ListItemIcon
            sx={{
              minWidth: 0,
              mr: sidebar.opened ? 1 : 'auto',
              justifyContent: 'center',
            }}
          >
            <Icon sx={{ color: '#fff' }} light />
          </ListItemIcon>
          <ListItemText
            primary={link.label}
            sx={{ opacity: sidebar.opened ? 1 : 0, color: '#fff' }}
          />
          {!!link.count && <Chip label={link.count} size="small" color="info" />}
          {sidebar.opened && onClick && (
            <IconButton
              onClick={(e) => {
                onClick();
                e.preventDefault();
              }}
            >
              {open ? <ExpandLess /> : onClick && <ExpandMore />}
            </IconButton>
          )}
        </ListItemButton>
      </Link>
    </ListItem>
  );
};

SidebarItem.propTypes = {
  link: PropTypes.object,
  open: PropTypes.bool,
  onClick: PropTypes.func,
};

const SidebarItemWithNested = ({ link }) => {
  const [open, setOpen] = useState(false);
  const sidebar = useContext(SidebarContext);

  useEffect(() => {
    setOpen(false);
  }, [sidebar.opened]);

  return (
    <>
      <SidebarItem link={link} open={open} onClick={() => setOpen(!open)} />
      <Collapse sx={{ pl: 3 }} in={open} timeout="auto" unmountOnExit>
        <SidebarLinks links={link.nestedLinks} />
      </Collapse>
    </>
  );
};

SidebarItemWithNested.propTypes = {
  link: PropTypes.object,
};

const SidebarLinks = ({ links }) => {
    const {t} = useTranslation();
    
  return (
      <List>
        {links.map((link, index) => {
          if (link.nestedLinks) {
            return <SidebarItemWithNested key={index} link={link}/>;
          }

          return <SidebarItem key={index} link={link}/>;
        })}
      </List>
  );
};

export default SidebarLinks;

SidebarLinks.propTypes = {
  links: PropTypes.array,
};
