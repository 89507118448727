import { all, takeEvery } from 'redux-saga/effects';

import {GET_COUNTS_REQUEST, GET_HP_REQUEST, LOGOUT_REQUEST, SIGN_IN_REQUEST} from '../actions/auth';

import {getCounts, getHp, logout, signIn} from './auth';

function* rootSaga() {
  yield all([
    takeEvery(SIGN_IN_REQUEST, signIn),
    takeEvery(LOGOUT_REQUEST, logout),
    takeEvery(GET_COUNTS_REQUEST, getCounts),
    takeEvery(GET_HP_REQUEST, getHp),
  ]);
}

export default rootSaga;
